import { isValidUUID } from '../utils/uuid';
import { format3DSDataRedirectIframe } from './formatters/challenge-3ds';
import { pendingPaymentArtefacts } from './formatters/future';
import formatPayload from './formatters/payload';
import { convertResponseToOldFormat } from './formatters/response';
import { rejectInvalidCheckout } from './modules/errors';

const fakeDemoResponse = (payload) =>
  Promise.resolve({
    demo: true,
    message: 'This is a demo. Not a real checkout is processed.',
    payload,
  });

export default function apiInit(api, product) {
  return {
    processCheckout: ({ checkoutId, payload, redirectMechanism }) => {
      const formattedPayload = formatPayload(payload);

      if (!checkoutId) {
        throw new Error(
          'Unable to process checkout without a valid checkoutId',
        );
      }

      if (checkoutId.toLowerCase().trim() === 'demo') {
        return fakeDemoResponse(formattedPayload);
      }

      if (!isValidUUID(checkoutId)) {
        return rejectInvalidCheckout(checkoutId);
      }

      if (redirectMechanism) {
        formattedPayload.sca_experience = redirectMechanism;
      }

      if (product) {
        formattedPayload.product_origin = product.origin;
        formattedPayload.session_id = product.sessionId;
      }

      return api
        .endpoint(`checkouts/${checkoutId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formattedPayload),
        })
        .then(convertResponseToOldFormat)
        .then(format3DSDataRedirectIframe)
        .then(pendingPaymentArtefacts);
    },
    createAppleSession: ({ checkoutId, payload }) => {
      const formattedPayload = formatPayload(payload);

      if (!isValidUUID(checkoutId)) {
        return rejectInvalidCheckout(checkoutId);
      }

      return api.endpoint(`checkouts/${checkoutId}/apple-pay-session`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedPayload),
      });
    },
  };
}

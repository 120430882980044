import enhanceResponseWithMetadata from './formatters/inject-metadata';
import { isValidUUID } from '../utils/uuid';
import { DEFAULT_PAYMENT_METHOD } from './constants';
import { rejectInvalidCheckout } from './modules/errors';
import { getSumupProduct } from '../utils/origin';

export default function init(api) {
  return {
    fetchByCheckout: ({ checkoutId }) => {
      const product = getSumupProduct(window.location.href);
      const isDemoCheckout = (checkoutId || '').toLowerCase().trim() === 'demo';
      if (isDemoCheckout || !checkoutId) {
        return Promise.resolve([DEFAULT_PAYMENT_METHOD]);
      }

      if (!isValidUUID(checkoutId)) {
        return rejectInvalidCheckout(checkoutId);
      }
      const params = new URLSearchParams();
      if (product?.origin === 'Hosted Checkout') {
        params.set('hosted_checkout', 'true');
      }

      return api
        .endpoint(
          `checkouts/${checkoutId}/payment-methods${
            `${params}` ? `?${params}` : ''
          }`,
        )
        .then(enhanceResponseWithMetadata);
    },
  };
}
